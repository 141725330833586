import { Component, For } from 'solid-js'
import { SelectProps } from '~/components/fields/select/select.interfaces'
import styles from '~/components/fields/select/select.module.scss'
import { fieldId } from '~/services/form/helpers'
import ChevronIcon from '~/assets/icons/chevron-down.svg'

const Select: Component<SelectProps> = ({ label, options, value, ...props }) => {
  const id = fieldId()

  return (
    <div class={styles.container}>
      <label
        for={id}
        class={styles.label}
      >
        {label}
      </label>
      
      <div class={styles.selectContainer}>
        <select
          id={id}
          class={styles.select}
          {...props}
        >
          <For each={options}>
            {option => (
              <option value={option.value} selected={value === option.value}>
                {option.label}
              </option>
            )}
          </For>
        </select>
        <ChevronIcon class={styles.icon} />
      </div>
    </div>
  )
}

export default Select
